<template>
    <fragment>
        <a :title="$t('button.title.addItem')"
           v-if="$global.hasPermission('clientsstore') && allowCreate > 0"
           v-b-tooltip.hover
           @click="handleAddClientClick"
           class="mr-1">
            <i class="fe fe-plus"></i>
        </a>
        <a :title="$t('button.title.editItem')"
           v-if="$global.hasPermission('clientsupdate') && hasId && allowUpdate > 0"
           @click="handleEditClientClick"
           v-b-tooltip.hover>
            <i class="fe fe-edit"></i>
        </a>
        <b-modal v-model="visibility" size="lg" :title="operationTitle" hide-footer>
            <div class="client-operation">
                <form @submit.prevent="handleSubmit" autocomplete="off">
                    <b-row>
                        <b-col cols="12">
                            <div v-show="editLoading">
                                <b-skeleton-table
                                    :rows="5"
                                    :columns="2"
                                    :table-props="{ bordered: true, striped: true }"
                                ></b-skeleton-table>
                            </div>

                            <div v-show="!editLoading">
                                <b-row>
                                    <b-col cols="12">
                                        <b-row>
                                            <b-col sm="4">
                                                <b-form-group
                                                    :label="$t('input.companyName')+' *'"
                                                    label-for="company_name"
                                                    :invalid-feedback="formErrors.first('company_name')"
                                                >
                                                    <b-form-input
                                                        id="company_name"
                                                        v-model="formFields.company_name"
                                                        type="text"
                                                        :state="((formErrors.has('company_name') ? false : null))"
                                                        ref="company_name"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="4">
                                                <b-form-group
                                                    :label="$t('input.phone')"
                                                    label-for="phone"
                                                    :invalid-feedback="formErrors.first('phone')"
                                                >
                                                    <b-form-input
                                                        id="phone"
                                                        v-model="formFields.phone"
                                                        type="text"
                                                        :state="((formErrors.has('phone') ? false : null))"
                                                        ref="phone"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="4">
                                                <b-form-group
                                                    :label="$t('input.email')+' *'"
                                                    label-for="email"
                                                    :invalid-feedback="formErrors.first('email')"
                                                >
                                                    <b-form-input
                                                        id="email"
                                                        v-model="formFields.email"
                                                        type="text"
                                                        :state="((formErrors.has('email') ? false : null))"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="4">
                                                <b-form-group
                                                    :label="$t('input.password')+' *'"
                                                    label-for="password"
                                                    :invalid-feedback="formErrors.first('password')"
                                                >
                                                    <b-form-input
                                                        id="password"
                                                        v-model="formFields.password"
                                                        type="text"
                                                        :state="((formErrors.has('password') ? false : null))"
                                                        ref="password"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="4">
                                                <b-form-group
                                                    :label="$t('input.fax')"
                                                    label-for="fax"
                                                    :invalid-feedback="formErrors.first('fax')"
                                                >
                                                    <b-form-input
                                                        id="fax"
                                                        v-model="formFields.fax"
                                                        type="text"
                                                        :state="((formErrors.has('fax') ? false : null))"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="4">
                                                <div class="form-group">
                                                    <div class="clearfix">
													<span class="pull-left">
														<label for="main_location" class="d-block">{{$t('input.mainLocation')}} *</label>
													</span><!-- /.pull-left -->
                                                        <span class="pull-right">
														<quick-location-form
                                                            allow-update="1"
                                                            :allow-create="(mainLocationDisable ? 0 : 1)"
                                                            :id="formFields.main_location_id"
                                                            :after-create="handleAfterQuickMainLocationCreated"
                                                            :after-update="handleAfterQuickMainLocationUpdated">
														</quick-location-form>
													</span><!-- /.pull-right -->
                                                    </div><!-- /.clearfix -->
                                                    <treeselect
                                                        :disabled="mainLocationDisable"
                                                        :multiple="false"
                                                        :options="mainLocations"
                                                        placeholder=""
                                                        v-model="formFields.main_location_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('main_location_id'))}]"
                                                    />
                                                    <div class="invalid-feedback">
                                                        {{formErrors.first('main_location_id')}}
                                                    </div>
                                                </div><!-- /.form-group -->
                                            </b-col><!--/b-col-->
                                            <b-col sm="4">
                                                <b-form-group
                                                    :label="$t('input.identificationNumber')+' *'"
                                                    label-for="identification_number"
                                                    :invalid-feedback="formErrors.first('identification_number')"
                                                >
                                                    <b-form-input
                                                        id="identification_number"
                                                        v-model="formFields.identification_number"
                                                        type="text"
                                                        :state="((formErrors.has('identification_number') ? false : null))"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                        </b-row><!--/b-row-->
                                    </b-col><!--/b-col-->
                                </b-row><!--/b-row-->
                            </div>

                            <div class="quick-modal-footer pt-1">
                                <b-button
                                    size="sm"
                                    type="submit"
                                    variant="primary"
                                    :disabled="global.pendingRequests > 0"
                                    v-b-tooltip.hover :title="$t('button.title.save')"
                                >
                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                 size="12px"
                                                 v-if="global.pendingRequests > 0"></clip-loader>
                                    <i class="fa fa-save mr-1"></i>
                                    {{$t('button.save')}}
                                </b-button>
                                <b-button variant="warning" class="ml-3"
                                          size="sm" @click="handleOperationClose"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div><!--/.quick-modal-footer-->
                        </b-col><!--/b-col-->
                    </b-row><!--/b-col-->
                </form>
            </div><!-- /.client-operation -->
        </b-modal>
    </fragment>
</template>
<script>
    import {Fragment} from 'vue-fragment'
    import Error from "./../../util/Error"
    import {request} from "../../util/Request"
    import {mapState} from "vuex"
    import Treeselect from '@riophae/vue-treeselect'
    import QuickLocationForm from "../locations/QuickLocationForm";


    const FORM_STATE = {
        main_location_id: null,
        phone: null,
        company_name: null,
        password: null,
        identification_number: null,
        email: null,
        fax: null,
        client_dealers: [],
        client_locations: [],
        client_contacts: [],
        client_brands: [],
        client_documents: [],
        _method: 'post',
    };

    export default {
        props: ['allowCreate', 'allowUpdate', 'id', 'afterCreate', 'afterUpdate', 'afterCancel', 'supplier'],
        components: {
            Fragment,
            Treeselect,
            QuickLocationForm,
        },
        data() {
            return {
                formErrors: new Error({}),
                formFields: {...FORM_STATE},
                visibility: false,
                editLoading: false,
                operationTitle: this.$t('title.addClient'),
                dropdowns: {
                    locations: [],
                }
            }
        },
        mounted() {
            this.getLocations();
        },
        methods: {
            async handleSubmit() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: this.formFields.id ? 'clients/update' : 'clients/create',
                        method: 'post',
                        data: this.formFields,
                    })

                    if (this.formFields.id) {
                        this.handleAfterClientUpdate(this.formFields, this.formFields.id);
                    } else {
                        this.handleAfterClientCreate(response.data);
                    }

                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            handleAddClientClick() {
                this.operationTitle = this.$t('title.client');
                this.visibility = true
            },
            handleEditClientClick() {
                this.operationTitle = this.$t('title.editClients');
                this.visibility = true
            },
            handleOperationClose() {
                this.formFields = {...FORM_STATE}
                this.visibility = false
                this.handleAfterOperationCancel()
            },
            handleAfterOperationCancel() {
                if (this.afterCancel) {
                    this.afterCancel()
                }
            },
            handleAfterClientCreate(inputs) {
                if (this.afterCreate) {
                    this.afterCreate(inputs)
                }
            },
            handleAfterClientUpdate(inputs) {
                if (this.afterUpdate) {
                    this.afterUpdate(inputs)
                }
            },
            handleAfterQuickMainLocationCreated(inputs) {
                this.dropdowns.locations.push({
                    id: inputs.id,
                    label: `${inputs.street} ${inputs.street_no} ${inputs.city} ${inputs.zip} ${inputs.country}`
                })
                this.formFields.main_location_id = inputs.id;
            },
            handleAfterQuickMainLocationUpdated(inputs) {
                const index = _.findIndex(this.dropdowns.locations, {id: inputs.id})
                this.$set(this.dropdowns.locations[index], 'label', `${inputs.street} ${inputs.street_no} ${inputs.city} ${inputs.zip} ${inputs.country}`);
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = data

                } catch (e) {
                    this.dropdowns.locations = []
                }
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
            hasId() {
                return (this.id)
            },
            mainLocations: {
                cache: false,
                get() {
                    return this.dropdowns.locations.map((item) => {
                        let locations = []

                        _.map(this.client_contacts, (contacts) => {
                            contacts.locations.map(location => locations.push(location))
                        })

                        return {
                            ...item,
                            isDisabled: (_.includes(locations, item.id))
                        }
                    });
                }
            },
            mainLocationDisable: {
                cache: false,
                get() {
                    let locations = []

                    _.map(this.client_contacts, (contacts) => {
                        contacts.locations.map(location => locations.push(location))
                    })

                    return _.includes(locations, this.formFields.main_location_id)
                }
            }
        }
    }
</script>
